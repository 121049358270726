import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
import Header from './components/Header';
import Hero from './components/Hero';
import Testimonials from './components/Testimonials';
import Footer from './components/Footer';
import Insights from './components/Insights';
import CTA from './components/CTA'; 
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';
import HowItWorks from './components/HowItWorks';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css"; 

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/terms" element={
          <div className="flex flex-col min-h-screen">
            <Header />
            <TermsOfService />
          </div>
        } />
        <Route path="/privacy" element={
          <div className="flex flex-col min-h-screen">
            <Header />
            <PrivacyPolicy />
          </div>
        } />
        <Route path="/" element={
          <div className="flex flex-col min-h-screen">
            <Header />
            <Hero />
            <HowItWorks />
            <Insights />
            <Testimonials />
            <CTA />
            <Footer />
          </div>
        } />
        {/* Add other routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;
