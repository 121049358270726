// src/components/CTA.js

import React from 'react';

const CTA = () => {
  return (
    <section
      className="m-0 px-4 py-12 sm:px-6 md:px-20 text-center"
      style={{
        background: 'linear-gradient(to right, rgba(5, 3, 49, 1), rgba(9, 5, 85, 1), rgba(6, 3, 62, 1), rgba(5, 3, 49, 1))',
      }}
    >
      <h3 className="text-2xl sm:text-3xl md:text-4xl text-white font-bold mb-4 sm:mb-6">
        🎙️ Get MORE out of your podcasts!
      </h3>
      <p className="text-lg sm:text-xl md:text-2xl text-white text-opacity-80 mb-8 sm:mb-12">
        Cut through the noise—get only the best.
      </p>
      <a href="https://forms.gle/tkZyErb78DgYsUi17" target="_blank" rel="noopener noreferrer">
        <button
          className="text-white py-3 px-6 sm:py-4 sm:px-10 rounded-md transition-colors duration-300 text-base sm:text-lg md:text-xl font-bold"
          style={{
            backgroundColor: '#6424e4', // Custom button color
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = '#5a1bb1')} // Custom hover effect
          onMouseLeave={(e) => (e.target.style.backgroundColor = '#6424e4')}
        >
          Start getting your insights today!
        </button>
      </a>
    </section>
  );
};

export default CTA;
