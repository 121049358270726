// src/components/HowItWorks.js

import React from 'react';

const HowItWorks = () => {
    return (
        <section
            className="w-full px-6 md:px-10 py-16 text-white"
            style={{
                background: 'linear-gradient(to right, rgba(0, 3, 49, 1), rgba(7, 16, 68, 1), rgba(6, 3, 62, 1), rgba(0, 3, 49, 1))',
            }}
        >
            <div className="max-w-6xl mx-auto text-center">
                <h2 className="text-4xl font-bold mb-8">How It Works</h2>
                
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div className="p-6 bg-opacity-20 bg-white rounded-lg shadow-md">
                        <h3 className="text-2xl font-semibold mb-4">🔎 Monitor Your Favorite Podcasts & Save Time</h3>
                        <p className="text-lg leading-relaxed">
                            Select your favorite podcasts, and we’ll monitor them for you.
                        </p>
                    </div>

                    <div className="p-6 bg-opacity-20 bg-white rounded-lg shadow-md">
                        <h3 className="text-2xl font-semibold mb-4">💡 Get the Best Insights</h3>
                        <p className="text-lg leading-relaxed">
                            We filter out the noise and pull the key highlights from authors and their guests.
                        </p>
                    </div>

                    <div className="p-6 bg-opacity-20 bg-white rounded-lg shadow-md">
                        <h3 className="text-2xl font-semibold mb-4">✉️ Daily Insights to Your Inbox</h3>
                        <p className="text-lg leading-relaxed">
                            Delivered fresh every day, powered by AI, so you never miss a beat.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HowItWorks;